import React, { useEffect } from 'react'
import { Grid, Typography, Dialog, IconButton } from '@mui/material'
import { useStyles } from './style'
import { ReButton } from '../../../components'
import { useBackdropContext } from '../../../contexts/backdrop'
import { Close } from '@mui/icons-material'

export default function FinishModal({ cancelOnBoarding, previous }) {
  const classes = useStyles()
  const backdrop = useBackdropContext()

  useEffect(() => {
    backdrop.setOpen(true)
    return () => backdrop.setOpen(false)
  }, [backdrop])

  return (
    <Dialog
      open
      classes={{ paperWidthSm: classes.modal }}
      hideBackdrop
    >
      <Grid style={{ position: 'absolute', top: 20, right: 20 }}>
        <IconButton onClick={cancelOnBoarding} color='primary'><Close style={{ fontSize: '30px' }} /></IconButton>
      </Grid>
      <Grid
        container
        justifyContent='center'
        alignItems='center'
        direction='column'
        rowSpacing={0}
        className={classes.root}
        marginBottom={2}
      >
        <Grid item xs={12} marginBottom={2}>
          <img
            src='https://static.resilienceengine.live/img/additional-user-settings-congratulations.png'
            alt='Additional User Settings Congratulations'
          />
          <Typography variant='h3' className={classes.title}>
            Congratulations!
          </Typography>
          <Typography variant='h3' className={classes.text}>
            You are ready to use the Resilience Dynamic® Dashboard
          </Typography>
        </Grid>
        <Grid xs={12} width='100%' item display='flex' justifyContent='center' gap={8}>
          <ReButton fullWidth name='Previous' dataTestId='close_congratulations_modal' action={previous} />
          <ReButton fullWidth name='Finish' dataTestId='close_congratulations_modal' action={cancelOnBoarding} />
        </Grid>
      </Grid>
    </Dialog>
  )
}

import { Grid } from '@mui/material'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { DashboardResults, ResilienceEvaluationCard } from '../../../components'
import { userSlice } from '../../../reducers'
import useResolvePathFromBaseURL from '../../../helpers/useResolvePathFromBaseURL'
import { getResilienceValues } from '../../../helpers/utils'
import { useDashboardContext } from '../../../hooks'
import GenericLoading from '../generic-loading'

const Evaluation = () => {
  const dashboardData = useSelector(userSlice.selectors.dashboardData)
  const { changeDashboardFilter } = useDashboardContext()
  const navigate = useNavigate()
  const selectedView = useSelector(userSlice.selectors.selectedView)
  const isOrgProfile = selectedView && selectedView.type === 'org'
  const { pathname: surveyPath } = useResolvePathFromBaseURL('survey')

  const resilienceValues = dashboardData.evaluationReportResult
    ? getResilienceValues(dashboardData.evaluationReportResult)
    : []

  if (changeDashboardFilter.isWorking) return <GenericLoading overrideHeight='240px' />

  return (
    <Grid item xs={12} display='flex' gap={2} marginY={1}>
      {resilienceValues.length || isOrgProfile ? (
        <DashboardResults
          title='Resilience Dynamic® Questionnaire Results'
          type='resilience'
          accState
          totalUsers={dashboardData.evaluationTotalUsers}
          results={dashboardData.evaluationReportResult}
          isOrgProfile={isOrgProfile}
          startHandler={() => navigate(surveyPath)}
        />
      ) : (
        <ResilienceEvaluationCard />
      )}
    </Grid>
  )
}
export default Evaluation

import React, { useEffect, useState } from 'react'
import { Box, Fade, Grid, IconButton, Typography } from '@mui/material'
import { useStyles } from './style'
import { ReButton } from '../..'
import PopperWrapper from '../popper-wrapper'
import { Close } from '@mui/icons-material'

export default function ToolpathwaysModal({ next, cancelOnBoarding }) {
  const classes = useStyles()
  const [ref, setRef] = useState(null)
  const [loaded, setLoaded] = useState(null)

  useEffect(() => {
    setTimeout(() => {
      setRef(document.querySelector('.onboarding-toolpathways'))
      setLoaded(true)
    }, 0)
  }, [])

  return (
    <PopperWrapper element={ref} placement='bottom'>
      <Fade in={loaded} timeout={400}>
        <Grid
          container
          justifyContent='center'
          direction='column'
          flexWrap='nowrap'
          className={classes.root}
        >
          <Grid style={{ position: 'absolute', top: 20, right: 20 }}>
            <IconButton onClick={cancelOnBoarding} color='primary'><Close style={{ fontSize: '30px' }} /></IconButton>
          </Grid>
          <Typography variant='h3' className={classes.title}>
            Tool Pathways
          </Typography>
          <Grid display='flex' marginBottom={3}>
            <Grid item xs={12} marginBottom={3}>
              <Typography component='p' variant='body3'>
                Tool Pathways provide personalised recommendations of tools from the Toolkit for you to complete. Once you complete your self-assessment, the Resilience Dynamic® Questionnaire or Indicator, you'll see a Resilience Level Tool Pathway which recommends tools for you based on your resilience level. If you have access to and are using the Resilience Tracker, you'll have an additional Tracking Pathway which recommends tools based on your tracking data from the previous week. Click to toggle between your Resilience Level Pathway and Tracking Pathway.
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.space}>
              <Box display='flex' justifyContent='center' >
                <img
                  src='https://static.resilienceengine.live/img/onboarding-toolpathways.svg'
                  alt='Additional User Settings Congratulations'
                  width='80%'
                />
              </Box>
            </Grid>
          </Grid>
          <Grid item display='flex' justifyContent='flex-end'>
            <ReButton fullWidth name='Next' dataTestId='close_congratulations_modal' action={next} />
          </Grid>
        </Grid>
      </Fade>
    </PopperWrapper >
  )
}
